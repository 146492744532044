@layer tokens {
    :where(:root, :host) {
  --itGdHF: DM Sans;
  --iCoXBY: Open Sans;
  --ksTgfC: #9995c5;
  --iCdJRO: #9b90d1;
  --dWAXWh: #b7a0e3;
  --jcWlLI: #d0b7f1;
  --eLlnzT: #5b5792;
  --jRGBpu: #6d68ad;
  --iDDIAz: #d3f0f3;
  --khfuXU: #aee2e6;
  --jdlUVz: #78c3b6;
  --bMEoOM: #658db2;
  --kxGpio: #fdf4dc;
  --cbZZGT: #ffebb5;
  --fwcFBW: #f9db89;
  --loWpng: #ad7725;
  --JsfmQ: #fbd5c1;
  --gBjjlD: #ffb398;
  --QMCLK: #fe8f68;
  --czilMg: #ae502e;
  --ioAkJk: #f2c1d2;
  --eyVWNz: #ffb3cc;
  --nXIqK: #f1a3bd;
  --hGgStN: #e3769a;
  --dviDWY: #a53c5e;
  --iYfRb: #ff4646;
  --lcYDiE: #ffffff;
  --hzpwrB: #f6f6f8;
  --lbfZji: #e7e7e7;
  --fcfsVb: #c1c1c1;
  --kPKNfw: #666666;
  --eQKgRp: #333333;
  --fwKFVa: #000000;
  --lfWTAN: #000000;
  --kRGICU: #000000;
  --EiDvL: #000000;
  --pSsxS: #000000;
  --fZeGdF: #000000;
  --gViRqS: 4px;
  --iwgChY: 8px;
  --lfVbxd: 12px;
  --djEgYq: 16px;
  --fTsGnv: 20px;
  --dtSMDw: 24px;
  --gdHlSB: 28px;
  --kPgycS: 32px;
  --cjznTb: 36px;
  --fSONOn: 40px;
  --fHXIbO: 44px;
  --VEOqF: 48px;
  --KNIEg: 52px;
  --QAYjL: 56px;
  --FJSxm: 60px;
  --hiMIkZ: 64px;
  --gXVCyA: 68px;
  --iwdpon: 72px;
  --ilmjBO: 76px;
  --cVdGvA: 80px;
  --jEpJZE: 8px;
  --kVgsuV: 8px;
  --jmVmzj: 10px;
  --drbyjO: 12px;
  --bwsDmB: 14px;
  --gPUywc: 14.4px;
  --iIlHsH: 16.8px;
  --cMrTdm: 19.2px;
  --tDvMp: 21.6px;
  --fNfqVQ: 24px;
  --fjQPQg: 28.8px;
  --cXteld: 38.4px;
  --isiGHO: 57.6px;
  --gfKVcL: 67.2px;
  --mCmFS: 16px;
  --kfvECQ: 10px;
  --ZsaMJ: 12px;
  --cTlXvO: 14px;
  --fcEdeD: 16px;
  --jiYuVY: 18px;
  --cURfR: 20px;
  --byPofm: 24px;
  --dIhtOb: 32px;
  --gKxkvv: 48px;
  --eAAmAq: 56px;
  --TgeWs: 400;
  --hmvyHR: 600;
  --ghWdwL: 700;
  --dfogYP: 1px;
  --flUDPF: 0px;
  --ikokJA: 544px;
  --kSvAmr: 768px;
  --uwdhN: 972px;
  --eyfDzy: 1200px;
  --dymagS: 1480px;
  --jqgiFF: 0px;
  --eomHOU: 544px;
  --iujnmj: 768px;
  --gThSgZ: 972px;
  --cssArG: 1200px;
  --bfIHqC: 1480px;
  --cyoBTj: var(--iYfRb);
  --eHHIwn: var(--khfuXU);
  --ieybZG: var(--fwcFBW);
  --hCRZxS: var(--bMEoOM)
}
  }
  