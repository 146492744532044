@layer tokens {
    @keyframes slideUp {
        0% {
            height: auto
        }
        100% {
            height: 0px
        }
    }
    @keyframes slideDown {
        0% {
            height: 0px
        }
        100% {
            height: auto
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1
        }
        100% {
            opacity: 0
        }
    }
    @keyframes contentShow {
        0% {
            opacity: 0;
            transform: translate(-50%, -48%) scale(0.96)
        }
        100% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1))
        }
    }
    @keyframes slideUpAndFade {
        0% {
            opacity: 0;
            transform: translateY(2px)
        }
        100% {
            opacity: 1;
            transform: translateY(0)
        }
    }
    @keyframes showSelectStatus {
        0% {
            opacity: 0;
            transform: translateY(-20px)
        }
        100% {
            opacity: 1;
            transform: translateY(0)
        }
    }
    @keyframes slideDownToast {
        0% {
            opacity: 0;
            transform: translateY(0)
        }
        100% {
            opacity: 1;
            transform: translateY(30px)
        }
    }
    @keyframes slideUpToast {
        0% {
            opacity: 0;
            transform: translateY(30px)
        }
        100% {
            opacity: 1;
            transform: translateY(0)
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(360deg)
        }
    }
}