@layer reset {
  * {
    margin: 0;
    padding: 0;
    font: inherit;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--global-color-border, currentColor);
  }

  html {
    line-height: 1.5;
    --font-fallback: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: var(--global-font-body, var(--font-fallback));
  }

  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  body {
    height: 100%;
    line-height: inherit;
  }

  img {
    border-style: none;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  ol,
  ul {
    list-style: none;
  }

  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }

  button,
  select {
    text-transform: none;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: var(--global-color-placeholder, #9ca3af);
  }

  textarea {
    resize: vertical;
  }

  summary {
    display: list-item;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  dialog {
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-size: 1em;
    --font-mono-fallback: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New';
    font-family: var(--global-font-mono, var(--font-fallback));
  }


  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none; 
  }

  input[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type='number']{
    -moz-appearance: textfield;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  :-moz-focusring {
    outline: auto;
  }
}